/* DARK MODE */

/* Diagonal stacked.serieItem-stack-dark effect */
.serieItem-stack-dark {
  background-color: rgb(206, 206, 206) !important;
  /* Need position to allow stacking of pseudo-elements */
  position: relative !important;
  border-radius: 14px;
}

.serieItem-stack-dark,
.serieItem-stack-dark::before,
.serieItem-stack-dark::after {
  /* Add shadow to distinguish sheets from one another */
  border-radius: 7px;
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.336) !important;
}

.serieItem-stack-dark::before,
.serieItem-stack-dark::after {
  content: "" !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgb(197, 197, 197) !important;
}


/* LIGHT MODE */

/* Second sheet of.serieItem-stack-dark */
.serieItem-stack-dark::before {
  left: 7px !important;
  top: 5px !important;
  z-index: -1 !important;
}

/* Third sheet of.serieItem-stack-dark */
.serieItem-stack-dark::after {
  left: 12px !important;
  top: 10px !important;
  z-index: -2 !important;
}

/* Diagonal stacked serieItem-stack effect */
.serieItem-stack {
  background-color: rgb(182, 182, 182) !important;
  /* Need position to allow stacking of pseudo-elements */
  position: relative !important;
  border-radius: 14px;
}

.serieItem-stack,
.serieItem-stack::before,
.serieItem-stack::after {
  /* Add shadow to distinguish sheets from one another */
  border-radius: 7px;
  box-shadow: 2px 1px 1px rgba(0, 0, 0, 0.274) !important;
}

.serieItem-stack::before,
.serieItem-stack::after {
  content: "" !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgb(216, 216, 216) !important;
}

/* Second sheet of serieItem-stack */
.serieItem-stack::before {
  left: 7px !important;
  top: 5px !important;
  z-index: -1 !important;
}

/* Third sheet of serieItem-stack */
.serieItem-stack::after {
  left: 12px !important;
  top: 10px !important;
  z-index: -2 !important;
}