.fade-enter {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active,
.fade-exit-active {
  transition: opacity 720ms ease, transform 720ms ease;
}
.opacity-enter {
  opacity: 0;
  transform: translateY(110%);
}
.opacity-enter-active {
  opacity: 1;
  transform: translateY(0%);
}
.opacity-exit {
  opacity: 1;
  transform: translateY(0%);
}
.opacity-exit-active {
  opacity: 0;
  transform: translateY(-110%);
}
.opacity-enter-active,
.opacity-exit-active {
  transition: opacity 720ms ease, transform 720ms ease;
}
