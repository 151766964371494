.vscroll::-webkit-scrollbar-track
{
	border-radius: 14px;
	background-color: transparent;
  opacity: .2;
}

.vscroll::-webkit-scrollbar
{
	width: 7px;
  height: 3px;
	background-color: transparent;
}

.vscroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  scrollbar-width: thin;

	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}