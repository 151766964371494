.magic {
  background: linear-gradient(to right,
      #8721FF,
      #38FFEF);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* white-space: nowrap; */
  overflow-wrap: break-word;
  transition-timing-function: ease;
}

@keyframes background-pan {
  from {
    background-position: 0% center;
  }

  to {
    background-position: 200% center;
  }
}